<template>
  <div>
    <head-banner />
    <AddHere />
    <b-row class="match-height mb-1">
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <h1 class="text-center">Hi {{ profileName }}</h1>
        <p class="text-center">These are the coins added by you</p>
      </b-col>
      <b-col lg="2">
        <b-form-group>
          <b-form-select v-model="selected" :options="options"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="match-height mb-1">
      <b-col lg="12">
        <b-table
          :filter="selected"
          id="table-crypto"
          hover
          :items="user_data"
          responsive
          :fields="!is_mobilesize ? fields : fields_mobile"
          class="mb-0"
          :tbody-transition-props="transProps"
          primary-key="id"
        >
          <!-- company -->
          <template #cell(no)="data">
            <span class="pl-1">
              {{ data.index + 1 }}
            </span>
          </template>
          <template #cell(name)="data">
            <div class="d-flex align-items-center">
              <b-avatar rounded size="45" variant="light-company">
                <b-img-lazy
                  center
                  fluid
                  :src="data.item.logo_link"
                  alt="avatar img"
                  class="image-size"
              /></b-avatar>
              <div>
                <div class="font-weight-bolder pl-1">
                  {{
                    !is_mobilesize
                      ? data.item.name
                      : data.item.name.slice(0, 8) + ".."
                  }}
                </div>
                <div class="font-small-2 text-muted pl-1" v-if="!is_mobilesize">
                  {{ data.item.symbol }}
                </div>
              </div>
            </div>
          </template>

          <!-- actual_market_cap -->
          <template #cell(actual_market_cap)="data">
            <div class="d-flex align-items-center">
              <!-- <b-avatar class="mr-1" :variant="data.item.avatarColor">
            <feather-icon size="18" :icon="data.item.avatarIcon" />
          </b-avatar> -->
              <span class="font-weight-bolder">
                $ {{ numeral(data.item.actual_market_cap).format("0,0") }}</span
              >
            </div>
          </template>

          <!-- release_date -->
          <template #cell(release_date)="data">
            <div class="d-flex flex-column">
              <span class="font-weight-bolder mb-25">{{
                data.item.release_date | diffForHumans
              }}</span>
            </div>
          </template>

          <!-- actual_price -->
          <template #cell(actual_price)="data">
            <span class="font-weight-bolder"
              >{{ "$" + data.item.actual_price.toFixed(12) }}
            </span>
          </template>
          <template #cell(status)="data">
            <div class="d-flex flex-column">
              <b-badge
                v-if="data.item.status == 'active'"
                variant="success"
                class="coin-status"
                >Posted</b-badge
              >
              <b-badge
                v-else-if="data.item.status == 'pending'"
                variant="warning"
                class="coin-status"
                >Pending</b-badge
              >
              <b-badge
                v-else-if="data.item.status == 'rejected'"
                variant="danger"
                class="coin-status"
                >Rejected</b-badge
              >
              <b-badge
                v-else-if="data.item.status == 'in-review'"
                variant="info gradient"
                class="coin-status"
                >In-review</b-badge
              >
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row class="match-height mb-1">
      <b-col lg="5"></b-col>
      <b-col lg="2" class="text-center">
        <b-button
          class="mt-2"
          variant="primary"
          type="submit"
          loading="true"
          @click="linked('add-coin')"
        >
          Add new coin
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import numeral from "numeral";
import { mixinList } from "@/mixins/mixinList";
import HeadBanner from "@/@core/components/carousel/HeadBanner.vue"

import {
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BTable,
  BAvatar,
  BImgLazy,
  BFormSelect,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AddHere from "@/@core/components/AddHere.vue";
// import vSelect from "vue-select";
export default {
  mixins: [mixinList],
  components: {
    BCol,
    BRow,
    BButton,
    BFormGroup,
    // vSelect,
    BTable,
    BAvatar,
    BImgLazy,
    BFormSelect,
    BBadge,
    AddHere,
    HeadBanner
  },
  directives: {
    Ripple,
  },
  created() {
    this.dayjs.extend(relativeTime);
  },
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return null;
      }

      return dayjs(date).fromNow();
    },
  },
  data() {
    return {
      dayjs,
      relativeTime,
      numeral,
      transProps: {
        // Transition name
        name: "flip-list",
      },
      selected: null,
      options: [
        { value: null, text: "All" },
        { value: "pending", text: "Pending" },
        { value: "active", text: "Posted" },
        { value: "rejected", text: "Rejected" },
        { value: "in-review", text: "In-review" },
      ],
      fields: [
        { key: "no", label: "NO" },
        { key: "name", label: "NAME" },
        { key: "actual_market_cap", label: "MARKET CAP" },
        { key: "release_date", label: "RELEASED" },
        { key: "actual_price", label: "PRICE" },
        { key: "status", label: "status" },
      ],
      fields_mobile: [
        { key: "no", label: "NO" },
        { key: "name", label: "NAME" },
        { key: "actual_market_cap", label: "MARKET CAP" },
        { key: "status", label: "status" },
      ],
    };
  },

  computed: {
    user_data() {
      return this.$store.state.crypto.user_data;
    },
    profileName() {
      let userData = JSON.parse(localStorage.getItem("profile"));
      if (userData) {
        return userData.name;
      }
      return "";
    },
  },
  methods: {
    linked: function (e) {
      this.$router.push({ path: "/" + e });
    },
  },
  mounted() {
    let userData = JSON.parse(localStorage.getItem("profile"));
    let id = userData.id;
    this.$store.dispatch("FETCH_USER_DATA", id);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
